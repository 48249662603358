import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const GenericPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section class="section container">
      <div class="content max-w-3xl mx-auto">
        <div className="text-center">
          <p className="text-sm md:text-base text-indigo-500 font-bold">Subscribe Sense</p>
          <h1 className="font-bold break-normal text-3xl md:text-4xl px-5 max-w-4xl m-auto">{title}</h1>
        </div>
        <PageContent className="content" content={content} />
      </div>
    </section>
  );
};

GenericPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const GenericPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout pageTitle={post.frontmatter.title} pageDescription={post.frontmatter.description}>
      <GenericPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

GenericPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GenericPage;

export const aboutPageQuery = graphql`
  query GenericPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
